import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { getFileUrl } from '@utils/file-manager';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { varAlpha } from 'src/theme/styles';

import { Image } from '../image';
import { Iconify } from '../iconify';
import { RejectionFiles } from './components/rejection-files';

import type { UploadProps } from './types';

// ----------------------------------------------------------------------

export const UploadAvatar = ({
  sx,
  error,
  value,
  disabled,
  helperText,
  type,
  ...other
}: UploadProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    accept: { 'image/*': [] },
    ...other,
  });

  const hasFile = !!value;

  const hasError = isDragReject || !!error;

  const [preview, setPreview] = useState<string>('');

  const isValidURL = (str: string) => {
    try {
      // eslint-disable-next-line no-new
      new URL(str);

      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    const previewImage = async () => {
      if (typeof value === 'string') {
        const prefix = 'https://supabase.orchestrax.com/storage/v1/object/public/asset';
        const isPublic = value.includes(prefix);

        const avatarUrl = isPublic || isValidURL(value) ? value : await getFileUrl(value, 126, 126);

        if (avatarUrl) setPreview(avatarUrl);
      } else if (value instanceof File) {
        setPreview(URL.createObjectURL(value));
      }
    };

    previewImage();
  }, [value]);

  const renderPreview = hasFile && (
    <Image
      alt="avatar"
      src={preview}
      objectFit="contain"
      sx={{
        width: 1,
        height: 1,
        borderRadius: type === 'brand' ? '5px' : '50%',
      }}
    />
  );

  const renderPlaceholder = (
    <Box
      className="upload-placeholder"
      sx={{
        top: 0,
        gap: 1,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 9,
        display: 'flex',
        borderRadius: type === 'brand' ? '5px' : '50%',
        position: 'absolute',
        alignItems: 'center',
        color: 'text.disabled',
        flexDirection: 'column',
        justifyContent: 'center',
        bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.08),
        transition: (theme) =>
          theme.transitions.create(['opacity'], { duration: theme.transitions.duration.shorter }),
        '&:hover': { opacity: 0.72 },
        ...(hasError && {
          color: 'error.main',
          bgcolor: (theme) => varAlpha(theme.vars.palette.error.mainChannel, 0.08),
        }),
        ...(hasFile && {
          zIndex: 9,
          opacity: 0,
          color: 'common.white',
          bgcolor: (theme) => varAlpha(theme.vars.palette.grey['900Channel'], 0.64),
        }),
      }}
    >
      <Iconify icon="solar:camera-add-bold" width={32} />

      <Typography variant="caption">{hasFile ? 'Update photo' : 'Upload photo'}</Typography>
    </Box>
  );

  const renderContent = (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        borderRadius: type === 'brand' ? '5px' : '50%',
        position: 'relative',
      }}
    >
      {renderPreview}
      {renderPlaceholder}
    </Box>
  );

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          p: 1,
          m: 'auto',
          width: 144,
          height: 144,
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: type === 'brand' ? '5px' : '50%',
          border: (theme) => `1px dashed ${varAlpha(theme.vars.palette.grey['500Channel'], 0.2)}`,
          ...(isDragActive && { opacity: 0.72 }),
          ...(disabled && { opacity: 0.48, pointerEvents: 'none' }),
          ...(hasError && { borderColor: 'error.main' }),
          ...(hasFile && {
            ...(hasError && {
              bgcolor: (theme) => varAlpha(theme.vars.palette.error.mainChannel, 0.08),
            }),
            '&:hover .upload-placeholder': { opacity: 1 },
          }),
          ...sx,
        }}
      >
        <input {...getInputProps()} />

        {renderContent}
      </Box>

      {helperText && helperText}

      <RejectionFiles files={fileRejections} />
    </>
  );
};
