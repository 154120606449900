import { supabase } from 'src/lib/supabase';

export const uploadFile = async (name: string, file: File | any) => {
  const fileType = file.type.split('/')[1];

  const { data, error } = await supabase.storage
    .from('avatars')
    .upload(`public/${name}_${new Date().getTime()}.${fileType || 'webp'}`, file, {
      cacheControl: '3600',
      upsert: false,
    });

  if (error) {
    console.error(error);
    throw error;
  }

  const { path } = data;

  return path;
};

export const uploadPublicFile = async (name: string, file: File | any) => {
  const fileType = file.type.split('/')[1];

  const { data, error } = await supabase.storage
    .from('asset')
    .upload(`media/${name}_${new Date().getTime()}.${fileType || 'webp'}`, file, {
      cacheControl: '3600',
      upsert: false,
    });

  if (error) {
    console.error(error);
    throw error;
  }

  const { path } = data;

  return path;
};

export const uploadPublicDocument = async (name: string, file: File | any) => {
  const fileType = file.type.split('/')[1];

  const { data, error } = await supabase.storage
    .from('asset')
    .upload(`docs/${name}_${new Date().getTime()}.${fileType}`, file, {
      cacheControl: '3600',
      upsert: false,
    });

  if (error) {
    console.error(error);
    throw error;
  }

  const { path } = data;

  return path;
};

export const getFileUrl = async (path: string, width?: number, height?: number) => {
  const { data } = await supabase.storage.from('avatars').createSignedUrl(path, 60000, {
    transform: {
      width,
      height,
    },
  });

  return data && data.signedUrl;
};

export const getFilePublicUrl = (path: string) => {
  const { data } = supabase.storage.from('asset').getPublicUrl(path);

  return data && data.publicUrl;
};
