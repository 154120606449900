import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Initial state
const sessionInitialState = {
  role: [],
  capabilities: [],
};

interface SessionState {
  role: string[];
  capabilities: string[];
}

// ** Set Auth Session
export const setAuthSession = createAsyncThunk<any, any, {}>(
  'authSession/setAuthSession',
  async (data) => data
);

export const authSessionSlice = createSlice({
  name: 'authSession',
  initialState: {
    authSession: <SessionState>{ ...sessionInitialState },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setAuthSession.fulfilled, (state, { payload }) => {
      state.authSession = payload;
    });
  },
});

export default authSessionSlice.reducer;
