import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_COURSE_SUBJECTS, FILTERED_COURSE_SUBJECTS } from '@api/course-subject';

import type { CourseSubject } from './types';

// ** Course-Subject initial state
const courseSubjectsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        course_id: 0,
        subject_id: 0,
        subject: {
          id: 0,
          name: null,
        },
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface CourseSubjectsState {
  edges: {
    cursor: string;
    node: Partial<CourseSubject>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch CourseSubjects
export const fetchCourseSubjects = createAsyncThunk<CourseSubject, any, {}>(
  'courseSubjects/fetchCourseSubjects',
  async (courseSubjectsData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_SUBJECTS,
        variables: { ...courseSubjectsData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered CourseSubjects
export const fetchFilteredCourseSubjects = createAsyncThunk<CourseSubject, any, {}>(
  'courseSubjects/fetchFilteredCourseSubjects',
  async (courseSubjectsData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_COURSE_SUBJECTS,
        variables: { ...courseSubjectsData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const courseSubjectsSlice = createSlice({
  name: 'courseSubjects',
  initialState: {
    courseSubjects: <CourseSubjectsState>{
      ...courseSubjectsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseSubjects.fulfilled, (state, { payload }) => {
        const { course_subjectCollection }: any = payload;

        state.courseSubjects = course_subjectCollection;
      })
      .addCase(fetchFilteredCourseSubjects.fulfilled, (state, { payload }) => {
        const { course_subjectCollection }: any = payload;

        state.courseSubjects = course_subjectCollection;
      });
  },
});

export default courseSubjectsSlice.reducer;
