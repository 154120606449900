import { paths } from 'src/routes/paths';

import { CONFIG } from 'src/config-global';

import { SvgColor } from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`${CONFIG.site.basePath}/assets/icons/dashboard/${name}.svg`} />
);

export const ICONS = {
  dashboard: icon('ic-dashboard'),
  courses: icon('ic-courses'),
  account: icon('ic-account'),
  notification: icon('ic-notification'),
  profile: icon('ic-profile'),
  systemAccounts: icon('ic-system-accounts'),
  migrateClient: icon('ic-migrate-client'),
  selfServiceManage: icon('ic-self-service-manage'),
  support: icon('ic-support'),
};

// ----------------------------------------------------------------------

export const navData = [
  /**
   * Brand Overview
   */
  {
    subheader: 'Brand Overview',
    items: [
      {
        title: 'Dashboard',
        path: paths.dashboard.root,
        icon: ICONS.dashboard,
      },
      {
        title: 'Courses',
        path: paths.dashboard.course.root,
        icon: ICONS.courses,
        children: [
          {
            title: 'Upload a Lead Course',
            path: paths.dashboard.course.create.lead,
            capabilities: ['course.create'],
          },
          {
            title: 'Course List',
            path: paths.dashboard.course.list,
            capabilities: [
              'course.create',
              'course.view',
              'course.edit',
              'course.qc',
              'course.pause',
              'course.site.approve',
            ],
          },
        ],
      },
    ],
  },

  /**
   * Account Management
   */
  {
    subheader: 'Account Management',
    items: [
      {
        title: 'Account',
        path: paths.dashboard.client.root,
        icon: ICONS.account,
        children: [
          {
            title: 'Manage Account',
            path: paths.dashboard.client.edit,
            capabilities: ['account.edit', 'account.qc', 'account.view'],
          },
          {
            title: 'Manage Brand',
            path: paths.dashboard.client.brand.edit,
            capabilities: ['brand.edit', 'brand.qc', 'brand.view', 'brand.create'],
          },
          {
            title: 'Campuses',
            path: paths.dashboard.client.campus.list,
            capabilities: [
              'campus_location.view',
              'campus_location.create',
              'campus_location.edit',
              'campus_location.delete',
            ],
          },
          {
            title: 'User Management',
            path: paths.dashboard.client.team.list,
            capabilities: ['user.edit', 'user.create', 'user.view'],
          },
        ],
      },
    ],
  },

  /**
   * User Preferences
   */ {
    subheader: 'User Preferences',
    items: [
      {
        title: 'Profile',
        path: paths.dashboard.user.profile,
        icon: ICONS.profile,
      },
    ],
  },

  /**
   * System Admin
   */ {
    subheader: 'System Admin',
    role: ['candlefox_super_user'],
    items: [
      {
        title: 'System Accounts',
        path: paths.dashboard.account.root,
        icon: ICONS.systemAccounts,
        children: [
          {
            title: 'Create System Account',
            path: paths.dashboard.account.create.systemAccount,
            capabilities: ['system.create', 'user.create.internal'],
          },
          {
            title: 'List System Accounts',
            path: paths.dashboard.account.list,
            capabilities: [
              'system.create',
              'user.create.internal',
              'user.view.internal',
              'user.edit.internal',
            ],
          },
        ],
      },
      {
        title: 'Migrate Existing Client',
        path: paths.dashboard.account.create.clientAccount,
        icon: ICONS.migrateClient,
        capabilities: ['user.create.internal'],
      },
      {
        title: 'Self Service Management',
        path: paths.dashboard.selfService.root,
        icon: ICONS.selfServiceManage,
        children: [
          {
            title: 'Subjects',
            path: paths.dashboard.selfService.subjectList,
            capabilities: ['subject.view', 'subject.create', 'subject.edit', 'subject.delete'],
          },
          {
            title: 'Recognitions',
            path: paths.dashboard.selfService.recognitionList,
            capabilities: [
              'recognition.view',
              'recognition.create',
              'recognition.edit',
              'recognition.delete',
            ],
          },
          {
            title: 'Partners',
            path: paths.dashboard.selfService.partnerList,
            capabilities: ['partner.create', 'partner.edit', 'partner.delete'],
          },
        ],
      },
    ],
  },

  /**
   * Help Center
   */
  {
    subheader: 'Help Center',
    items: [
      {
        title: 'Support',
        path: paths.dashboard.support.root,
        icon: ICONS.support,
      },
    ],
  },
];
