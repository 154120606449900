import { gql } from '@apollo/client';

const CORE_FAQS = gql`
  fragment CoreFaqs on faqs {
    nodeId
    id
    title
    content
    images
    category
    author
    faqs
    keywords
    video
    quick_links
    status
    impressions
    created_at
    profiles {
      first_name
      last_name
      avatar_url
    }
  }
`;

export const GET_FAQS = gql`
  ${CORE_FAQS}
  query GetFaqs($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    faqsCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreFaqs
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_FAQS = gql`
  ${CORE_FAQS}
  query FilteredFaqs(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $title: String
    $category: String
    $keywords: String
    $status: String
  ) {
    faqsCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: {
        or: {
          title: { ilike: $title }
          category: { eq: $category }
          keywords: { contains: $keywords }
          status: { eq: $status }
        }
      }
    ) {
      edges {
        cursor
        node {
          ...CoreFaqs
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_FAQ_BY_NODE_ID = gql`
  ${CORE_FAQS}
  query GetFaqsByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on faqs {
        ...CoreFaqs
      }
    }
  }
`;

export const GET_FAQ_BY_ID = gql`
  ${CORE_FAQS}
  query GetFaqsById($id: UUID!) {
    faqsCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreFaqs
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_FAQ = gql`
  ${CORE_FAQS}
  mutation CreateFaqs(
    $title: String!
    $content: String!
    $images: [String]
    $category: String
    $author: UUID
    $faqs: JSON
    $keywords: [String]
    $video: String
    $quick_links: [String]
    $status: String
    $impressions: BigInt
  ) {
    insertIntofaqsCollection(
      objects: [
        {
          title: $title
          content: $content
          images: $images
          category: $category
          author: $author
          faqs: $faqs
          keywords: $keywords
          video: $video
          quick_links: $quick_links
          status: $status
          impressions: $impressions
        }
      ]
    ) {
      affectedCount
      records {
        ...CoreFaqs
      }
    }
  }
`;

export const UPDATE_FAQ_BY_ID = gql`
  ${CORE_FAQS}
  mutation UpdateFaqsById(
    $nodeId: ID
    $id: UUID
    $title: String
    $content: String
    $images: [String]
    $category: String
    $author: UUID
    $faqs: JSON
    $keywords: [String]
    $video: String
    $quick_links: [String]
    $status: String
    $impressions: BigInt
  ) {
    updatefaqsCollection(
      set: {
        title: $title
        content: $content
        images: $images
        category: $category
        author: $author
        faqs: $faqs
        keywords: $keywords
        video: $video
        quick_links: $quick_links
        status: $status
        impressions: $impressions
      }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreFaqs
      }
    }
  }
`;

export const DELETE_FAQ_BY_ID = gql`
  mutation DeleteFaqsById($ids: [UUID!]!) {
    deleteFromfaqsCollection(filter: { id: { in: $ids } }, atMost: 50) {
      affectedCount
      records {
        id
      }
    }
  }
`;
