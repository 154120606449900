import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_COURSE_RECOGNITION,
  GET_COURSE_RECOGNITION_BY_ID,
  DELETE_COURSE_RECOGNITION_BY_ID,
  UPDATE_COURSE_RECOGNITION_BY_ID,
  GET_COURSE_RECOGNITION_BY_NODE_ID,
} from '@api/course-recognition';

import type { CourseRecognition } from '../types';

// ** Course-Recognition initial state
const courseRecognitionInitialState = {
  nodeId: null,
  id: 0,
  course_id: 0,
  recognition_id: 0,
  recognition: {
    id: 0,
    name: null,
    logo: null,
  },
};

// ** Fetch Single CourseRecognition
export const fetchSingleCourseRecognition = createAsyncThunk<
  CourseRecognition,
  { nodeId: string },
  {}
>('courseRecognition/fetchSingleCourseRecognition', async (nodeId, { rejectWithValue }) => {
  try {
    const { data } = await apolloClient.query({
      query: GET_COURSE_RECOGNITION_BY_NODE_ID,
      variables: { ...nodeId },
      fetchPolicy: 'no-cache',
    });

    return data;
  } catch (err) {
    const error: any = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
});

// ** Fetch Single CourseRecognition By ID
export const fetchSingleCourseRecognitionById = createAsyncThunk<
  CourseRecognition,
  { id: number },
  {}
>('courseRecognition/fetchSingleCourseRecognitionById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await apolloClient.query({
      query: GET_COURSE_RECOGNITION_BY_ID,
      variables: { ...id },
      fetchPolicy: 'no-cache',
    });

    return data;
  } catch (err) {
    const error: any = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
});

// ** Create CourseRecognition
export const createCourseRecognition = createAsyncThunk<CourseRecognition, any, {}>(
  'courseRecognition/createCourseRecognition',
  async (courseRecognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_COURSE_RECOGNITION,
        variables: { records: courseRecognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update CourseRecognition
export const updateCourseRecognition = createAsyncThunk<
  CourseRecognition,
  Partial<CourseRecognition>,
  {}
>(
  'courseRecognition/updateCourseRecognition',
  async (courseRecognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_COURSE_RECOGNITION_BY_ID,
        variables: { ...courseRecognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete CourseRecognition
export const deleteCourseRecognition = createAsyncThunk<CourseRecognition, any, {}>(
  'courseRecognition/deleteCourseRecognition',
  async (courseRecognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_COURSE_RECOGNITION_BY_ID,
        variables: { ids: courseRecognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleCourseRecognitionSlice = createSlice({
  name: 'courseRecognition',
  initialState: {
    courseRecognition: <Partial<CourseRecognition>>{
      ...courseRecognitionInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCourseRecognition.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.courseRecognition = node;
      })
      .addCase(fetchSingleCourseRecognitionById.fulfilled, (state, { payload }) => {
        const {
          course_recognitionCollection: { edges },
        }: any = payload;

        state.courseRecognition = edges[0].node;
      })
      .addCase(createCourseRecognition.fulfilled, (state, { payload }) => {
        const {
          insertIntocourse_recognitionCollection: { records },
        }: any = payload;

        state.courseRecognition = records[0];
      })
      .addCase(updateCourseRecognition.fulfilled, (state, { payload }) => {
        const {
          updatecourse_recognitionCollection: { records },
        }: any = payload;

        state.courseRecognition = records[0];
      })
      .addCase(deleteCourseRecognition.fulfilled, (state, { payload }) => {
        const {
          deleteFromcourse_recognitionCollection: { records },
        }: any = payload;

        state.courseRecognition = records[0];
      });
  },
});

export default singleCourseRecognitionSlice.reducer;
