import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_COURSE_SUBJECT,
  GET_COURSE_SUBJECT_BY_ID,
  DELETE_COURSE_SUBJECT_BY_ID,
  UPDATE_COURSE_SUBJECT_BY_ID,
  GET_COURSE_SUBJECT_BY_NODE_ID,
} from '@api/course-subject';

import type { CourseSubject } from '../types';

// ** Course-Subject initial state
const courseSubjectInitialState = {
  nodeId: null,
  id: 0,
  course_id: 0,
  subject_id: 0,
  subject: {
    id: 0,
    name: null,
  },
};

// ** Fetch Single CourseSubject
export const fetchSingleCourseSubject = createAsyncThunk<CourseSubject, { nodeId: string }, {}>(
  'courseSubject/fetchSingleCourseSubject',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_SUBJECT_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single CourseSubject By ID
export const fetchSingleCourseSubjectById = createAsyncThunk<CourseSubject, { id: number }, {}>(
  'courseSubject/fetchSingleCourseSubjectById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_SUBJECT_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create CourseSubject
export const createCourseSubject = createAsyncThunk<CourseSubject, any, {}>(
  'courseSubject/createCourseSubject',
  async (courseSubjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_COURSE_SUBJECT,
        variables: { records: courseSubjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update CourseSubject
export const updateCourseSubject = createAsyncThunk<CourseSubject, Partial<CourseSubject>, {}>(
  'courseSubject/updateCourseSubject',
  async (courseSubjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_COURSE_SUBJECT_BY_ID,
        variables: { ...courseSubjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete CourseSubject
export const deleteCourseSubject = createAsyncThunk<CourseSubject, any, {}>(
  'courseSubject/deleteCourseSubject',
  async (courseSubjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_COURSE_SUBJECT_BY_ID,
        variables: { ids: courseSubjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleCourseSubjectSlice = createSlice({
  name: 'courseSubject',
  initialState: {
    courseSubject: <Partial<CourseSubject>>{
      ...courseSubjectInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCourseSubject.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.courseSubject = node;
      })
      .addCase(fetchSingleCourseSubjectById.fulfilled, (state, { payload }) => {
        const {
          course_subjectCollection: { edges },
        }: any = payload;

        state.courseSubject = edges[0].node;
      })
      .addCase(createCourseSubject.fulfilled, (state, { payload }) => {
        const {
          insertIntocourse_subjectCollection: { records },
        }: any = payload;

        state.courseSubject = records[0];
      })
      .addCase(updateCourseSubject.fulfilled, (state, { payload }) => {
        const {
          updatecourse_subjectCollection: { records },
        }: any = payload;

        state.courseSubject = records[0];
      })
      .addCase(deleteCourseSubject.fulfilled, (state, { payload }) => {
        const {
          deleteFromcourse_subjectCollection: { records },
        }: any = payload;

        state.courseSubject = records[0];
      });
  },
});

export default singleCourseSubjectSlice.reducer;
