import type { ButtonProps } from '@mui/material/Button';
import type { Theme, SxProps } from '@mui/material/styles';

import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { SvgColor } from '@components/svg-color';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import { CONFIG } from 'src/config-global';

import { toast } from 'src/components/snackbar';

import { useAuthContext } from 'src/auth/hooks';
import { signOut as jwtSignOut } from 'src/auth/context/jwt/action';
import { signOut as amplifySignOut } from 'src/auth/context/amplify/action';
import { signOut as supabaseSignOut } from 'src/auth/context/supabase/action';
import { signOut as firebaseSignOut } from 'src/auth/context/firebase/action';

// ----------------------------------------------------------------------

const signOut =
  (CONFIG.auth.method === 'supabase' && supabaseSignOut) ||
  (CONFIG.auth.method === 'firebase' && firebaseSignOut) ||
  (CONFIG.auth.method === 'amplify' && amplifySignOut) ||
  jwtSignOut;

type Props = ButtonProps & {
  sx?: SxProps<Theme>;
  onClose?: () => void;
  btnType?: string;
};

export const SignOutButton = ({ onClose, btnType, ...other }: Props) => {
  const router = useRouter();

  const { checkUserSession } = useAuthContext();

  const { logout: signOutAuth0 } = useAuth0();

  const handleLogout = useCallback(async () => {
    try {
      // Remove items from localStorage
      window.localStorage.removeItem('bId');
      window.localStorage.removeItem('bIds');
      window.localStorage.removeItem('bS');

      await signOut();
      await checkUserSession?.();

      onClose?.();
      router.replace('/');
      router.refresh();
    } catch (error) {
      console.error(error);
      toast.error('Unable to logout!');
    }
  }, [checkUserSession, onClose, router]);

  const handleLogoutAuth0 = useCallback(async () => {
    try {
      await signOutAuth0();

      onClose?.();
      router.refresh();
    } catch (error) {
      console.error(error);
      toast.error('Unable to logout!');
    }
  }, [onClose, router, signOutAuth0]);

  return (
    <Button
      fullWidth
      variant="text"
      size="large"
      color="inherit"
      onClick={CONFIG.auth.method === 'auth0' ? handleLogoutAuth0 : handleLogout}
      sx={{ justifyContent: btnType === 'mini' ? 'center' : 'flex-start' }}
      {...other}
    >
      <Stack
        direction={btnType === 'mini' ? 'column' : 'row'}
        spacing={btnType === 'mini' ? 0.8 : 1.5}
        alignItems="center"
        sx={{ pl: btnType === 'mini' ? 0 : 0.35 }}
      >
        <SvgColor src={`${CONFIG.site.basePath}/assets/icons/dashboard/ic-logout.svg`} />
        <Typography
          variant="body2"
          fontWeight={btnType === 'mini' ? 600 : 500}
          sx={{ opacity: 0.8, fontSize: btnType === 'mini' ? '0.625rem' : '0.875rem' }}
        >
          Logout
        </Typography>
      </Stack>
    </Button>
  );
};
