import type { RootState, AppDispatch } from '@redux/index';
import type { NavSectionProps } from 'src/components/nav-section';

import { supabase } from '@lib/supabase';
import { stringAvatar } from '@utils/avatar';
import { fCurrency } from '@utils/format-number';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { fetchFilteredNotifications } from '@redux/features/notification';
import { setNoticesStateData } from '@redux/features/notification/noticesData';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { CONFIG } from 'src/config-global';

import { HeaderSection } from './header-section';
import { MenuButton } from '../components/menu-button';
import { SignInButton } from '../components/sign-in-button';
import { AccountDrawer } from '../components/account-drawer';
import {
  NotificationsDrawer,
  type NotificationsDrawerProps,
} from '../components/notifications-drawer';

import type { HeaderSectionProps } from './header-section';
import type { AccountDrawerProps } from '../components/account-drawer';
import type { ContactsPopoverProps } from '../components/contacts-popover';
import type { LanguagePopoverProps } from '../components/language-popover';

// ----------------------------------------------------------------------

const StyledDivider = styled('span')(({ theme }) => ({
  width: 1,
  height: 10,
  flexShrink: 0,
  display: 'none',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: theme.spacing(2.5),
  marginRight: theme.spacing(2.5),
  backgroundColor: 'currentColor',
  color: theme.vars.palette.divider,
  '&::before, &::after': {
    top: -5,
    width: 3,
    height: 3,
    content: '""',
    flexShrink: 0,
    borderRadius: '50%',
    position: 'absolute',
    backgroundColor: 'currentColor',
  },
  '&::after': { bottom: -5, top: 'auto' },
}));

// ----------------------------------------------------------------------

export type HeaderBaseProps = HeaderSectionProps & {
  onOpenNav: () => void;
  data?: {
    nav?: NavSectionProps['data'];
    account?: AccountDrawerProps['data'];
    langs?: LanguagePopoverProps['data'];
    contacts?: ContactsPopoverProps['data'];
    notifications?: NotificationsDrawerProps['data'];
  };
  slots?: {
    navMobile?: {
      topArea?: React.ReactNode;
      bottomArea?: React.ReactNode;
    };
  };
  slotsDisplay?: {
    signIn?: boolean;
    account?: boolean;
    helpLink?: boolean;
    settings?: boolean;
    purchase?: boolean;
    contacts?: boolean;
    searchbar?: boolean;
    workspaces?: boolean;
    menuButton?: boolean;
    localization?: boolean;
    notifications?: boolean;
  };
};

export const HeaderBase = ({
  sx,
  data,
  slots,
  slotProps,
  onOpenNav,
  layoutQuery,
  slotsDisplay: {
    signIn = true,
    account = true,
    helpLink = true,
    purchase = true,
    menuButton = true,
  } = {},
  ...other
}: HeaderBaseProps) => {
  // ** States
  const [balance, setBalance] = useState<string>('0');
  const [notifications, setNotifications] = useState<any[]>([]);

  // ** Hooks
  const theme = useTheme();
  const { brandId = '' } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { activeBrand } = useSelector((state: RootState) => state.activeBrand);
  const { authSession } = useSelector((state: RootState) => state.authSession);

  // ** Vars
  const isClientRole = authSession?.role[0]?.includes('client');

  const fetchNotifications = useCallback(async () => {
    if (activeBrand.nodeId) {
      const notices = await dispatch(
        fetchFilteredNotifications({
          first: 100,
          ...(isClientRole
            ? { account_id: activeBrand.account_id, visibility: 'external' }
            : { visibility: 'internal' }),
        })
      );

      const {
        notificationCollection: { edges, pageInfo },
      }: any = notices.payload;

      await dispatch(
        setNoticesStateData({
          notifications: edges?.map((item: any) => item?.node),
          notificationsPageInfo: pageInfo,
        })
      );

      setNotifications(edges?.map((item: any) => item?.node));
    }
  }, [activeBrand.account_id, activeBrand.nodeId, dispatch, isClientRole]);

  const fetchRealtimeBalance = useCallback(async () => {
    // Function to handle updates
    const handleRemainingSpendUpdate = (payload: any) => {
      const {
        new: { remaining_spend },
      } = payload;

      setBalance(remaining_spend);
    };

    // Listen to realtime updates
    await supabase
      .channel('brand')
      .on(
        'postgres_changes',
        { event: 'UPDATE', schema: 'public', table: 'brand', filter: `id=eq.${brandId}` },
        handleRemainingSpendUpdate
      )
      .subscribe();
  }, [brandId]);

  const fetchLatestSFBalance = useCallback(async () => {
    const url = `${CONFIG.supabase.url}/functions/v1/worker/get-brand-spend?id=${brandId}&method=POST`;
    const token = (await supabase.auth.getSession()).data.session?.access_token;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          apikey: CONFIG.supabase.key,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Response was not OK!');
      }

      const result = await response;

      return result;
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      return error.message;
    }
  }, [brandId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (brandId !== '' && activeBrand?.spend_cap) {
      // Set initial balance
      if (activeBrand?.remaining_spend) setBalance(`${activeBrand?.remaining_spend}`);

      // Get Realtime Balance
      fetchRealtimeBalance();
      fetchLatestSFBalance();

      // Fetch latest balance from SF
      const balanceIntervalId = setInterval(fetchLatestSFBalance, 300000); // Run every 5 minutes

      // Clean up the interval on component unmount
      return () => clearInterval(balanceIntervalId);
    }
  }, [
    activeBrand?.remaining_spend,
    activeBrand?.spend_cap,
    brandId,
    dispatch,
    fetchLatestSFBalance,
    fetchRealtimeBalance,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (brandId !== '') {
      // Fetch notifications
      fetchNotifications();

      // Set up the polling interval
      const noticesIntervalId = setInterval(fetchNotifications, 300000); // Run every 5 minutes

      // Clean up the interval on component unmount
      return () => clearInterval(noticesIntervalId);
    }
  }, [brandId, fetchNotifications, isClientRole]);

  return (
    <HeaderSection
      sx={sx}
      layoutQuery={layoutQuery}
      slots={{
        ...slots,
        leftAreaStart: slots?.leftAreaStart,
        leftArea: (
          <>
            {slots?.leftAreaStart}

            {/* -- Menu button -- */}
            {menuButton && (
              <MenuButton
                data-slot="menu-button"
                onClick={onOpenNav}
                sx={{ mr: 1, ml: -1, [theme.breakpoints.up(layoutQuery)]: { display: 'none' } }}
              />
            )}

            {/* -- Divider -- */}
            <StyledDivider data-slot="divider" />

            {slots?.leftAreaEnd}
          </>
        ),
        rightArea: (
          <>
            {slots?.rightAreaStart}

            <Box
              data-area="right"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: 1, sm: 1.5 },
              }}
            >
              {/* -- Help link -- */}
              {helpLink && (
                <Link
                  data-slot="help-link"
                  href={paths.faqs}
                  component={RouterLink}
                  color="inherit"
                  sx={{ typography: 'subtitle2' }}
                >
                  Need help?
                </Link>
              )}

              {/* -- Notifications popover -- */}
              {notifications.length > 0 && (
                <NotificationsDrawer data-slot="notifications" sx={{ mr: 1 }} />
              )}

              {/* -- Account drawer -- */}
              {account && <AccountDrawer data-slot="account" data={data?.account} />}

              {/* -- Brand Account balance -- */}
              {account && activeBrand.spend_cap && (
                <Stack direction="row" spacing={2} marginInlineStart={1.5} alignItems="center">
                  {activeBrand.provider_name && (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'white',
                        px: 0.3,
                        borderRadius: 1,
                        border: '1px solid var(--palette-grey-200)',
                      }}
                    >
                      {activeBrand.logo ? (
                        <Avatar
                          alt={activeBrand.provider_name}
                          src={activeBrand.logo}
                          sx={{
                            width: 36,
                            height: 36,
                            '& img': { objectFit: 'contain' },
                          }}
                        />
                      ) : (
                        <Avatar
                          {...stringAvatar(`${activeBrand.provider_name}`)}
                          sx={{ width: 36, height: 36, fontSize: 14 }}
                        />
                      )}
                    </Stack>
                  )}

                  <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }}>
                    Balance: {`${fCurrency(activeBrand.remaining_spend ? Number(balance) : 0)}`}
                  </Typography>
                </Stack>
              )}

              {/* -- Sign in button -- */}
              {signIn && <SignInButton />}

              {/* -- Purchase button -- */}
              {purchase && (
                <Button
                  data-slot="purchase"
                  variant="contained"
                  rel="noopener"
                  target="_blank"
                  href={paths.minimalStore}
                  sx={{
                    display: 'none',
                    [theme.breakpoints.up(layoutQuery)]: { display: 'inline-flex' },
                  }}
                >
                  Purchase
                </Button>
              )}
            </Box>

            {slots?.rightAreaEnd}
          </>
        ),
      }}
      slotProps={slotProps}
      {...other}
    />
  );
};
