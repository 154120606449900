import { useState, useCallback } from 'react';
import { Iconify } from '@components/iconify';
import { UploadBox } from '@components/upload';
import { getFilePublicUrl, uploadPublicFile } from '@utils/file-manager';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { editorClasses } from '../classes';
import { ToolbarItem } from './toolbar-item';

import type { EditorToolbarProps } from '../types';

// ----------------------------------------------------------------------

export const ImageBlock = ({ editor }: Pick<EditorToolbarProps, 'editor'>) => {
  // ** States
  const [url, setUrl] = useState<string>('');
  const [file, setFile] = useState<File | string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    setFile(newFile);
  }, []);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleImageUpload = useCallback(async () => {
    const logoIsObject = file && typeof file === 'object';
    const logoUrl =
      logoIsObject && (await uploadPublicFile(`${file.name.replace(/\s+/g, '')}`, file));

    const logoPublicUrl = logoUrl && getFilePublicUrl(logoUrl);

    setUrl(logoPublicUrl || '');

    return logoPublicUrl;
  }, [file]);

  const handleUpdateUrl = useCallback(async () => {
    handleClosePopover();

    if (anchorEl) {
      if (file) {
        const imageLink = await handleImageUpload();

        if (imageLink) {
          editor?.chain().focus().setImage({ src: imageLink }).run();
        }
      } else {
        editor?.chain().focus().setImage({ src: url }).run();
      }
    }
  }, [anchorEl, editor, file, handleImageUpload, url]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <ToolbarItem
        aria-label="Image"
        className={editorClasses.toolbar.image}
        onClick={handleOpenPopover}
        icon={
          <path d="M20 5H4V19L13.2923 9.70649C13.6828 9.31595 14.3159 9.31591 14.7065 9.70641L20 15.0104V5ZM2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z" />
        }
      />

      <Popover
        id={anchorEl ? 'simple-popover' : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        slotProps={{ paper: { sx: { p: 2.5 } } }}
      >
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Image
        </Typography>

        <Stack alignItems="center" spacing={1}>
          <Box sx={{ width: '100%' }}>
            <UploadBox
              value={file}
              onDrop={handleDropSingleFile}
              onDelete={() => setFile(null)}
              placeholder={
                <Stack spacing={0.5} alignItems="center">
                  <Iconify icon="eva:cloud-upload-fill" width={40} />
                  <Typography variant="body2">Upload image</Typography>
                </Stack>
              }
              sx={{ width: 240, height: 'auto' }}
            />
          </Box>

          <Button variant="contained" onClick={handleUpdateUrl}>
            Apply
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
