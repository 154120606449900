import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_FAQ,
  GET_FAQ_BY_ID,
  DELETE_FAQ_BY_ID,
  UPDATE_FAQ_BY_ID,
  GET_FAQ_BY_NODE_ID,
} from '@api/faq';

import type { FAQ } from '../types';

// ** FAQ initial state
const faqInitialState = {
  nodeId: null,
  id: '',
  title: '',
  content: '',
  images: null,
  category: '',
  author: null,
  faqs: null,
  keywords: null,
  video: null,
  quick_links: null,
  status: null,
  impressions: null,
  created_at: null,
  profiles: {
    first_name: null,
    last_name: null,
    avatar_url: null,
  },
};

// ** Fetch Single FAQ
export const fetchSingleFAQ = createAsyncThunk<FAQ, { nodeId: string }, {}>(
  'faq/fetchSingleFAQ',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_FAQ_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single FAQ By ID
export const fetchSingleFAQById = createAsyncThunk<FAQ, { id: string }, {}>(
  'faq/fetchSingleFAQById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_FAQ_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create FAQ
export const createFAQ = createAsyncThunk<FAQ, Partial<FAQ>, {}>(
  'faq/createFAQ',
  async (faqData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_FAQ,
        variables: { ...faqData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update FAQ
export const updateFAQ = createAsyncThunk<FAQ, Partial<FAQ>, {}>(
  'faq/updateFAQ',
  async (faqData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_FAQ_BY_ID,
        variables: { ...faqData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete FAQ
export const deleteFAQ = createAsyncThunk<FAQ, Partial<FAQ>, {}>(
  'faq/deleteFAQ',
  async (faqData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_FAQ_BY_ID,
        variables: { ...faqData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleFAQSlice = createSlice({
  name: 'faq',
  initialState: {
    faq: <Partial<FAQ>>{
      ...faqInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleFAQ.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.faq = node;
      })
      .addCase(fetchSingleFAQById.fulfilled, (state, { payload }) => {
        const {
          faqsCollection: { edges },
        }: any = payload;

        state.faq = edges[0].node;
      })
      .addCase(createFAQ.fulfilled, (state, { payload }) => {
        const {
          insertIntofaqsCollection: { records },
        }: any = payload;

        state.faq = records[0];
      })
      .addCase(updateFAQ.fulfilled, (state, { payload }) => {
        const {
          updatefaqsCollection: { records },
        }: any = payload;

        state.faq = records[0];
      })
      .addCase(deleteFAQ.fulfilled, (state, { payload }) => {
        const {
          deleteFromfaqsCollection: { records },
        }: any = payload;

        state.faq = records[0];
      });
  },
});

export default singleFAQSlice.reducer;
