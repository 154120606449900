import type { Theme, SxProps, CSSObject } from '@mui/material/styles';

import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';

import { layoutClasses } from '../classes';

// ----------------------------------------------------------------------

export type LayoutSectionProps = {
  sx?: SxProps<Theme>;
  cssVars?: CSSObject;
  children?: React.ReactNode;
  footerSection?: React.ReactNode;
  headerSection?: React.ReactNode;
  sidebarSection?: React.ReactNode;
};

// ----------------------------------------------------------------------

export const LayoutSection = ({
  sx,
  cssVars,
  children,
  footerSection,
  headerSection,
  sidebarSection,
}: LayoutSectionProps) => {
  // ** States
  const [isStaging, setIsStaging] = useState<boolean>(false);

  useEffect(() => {
    const mainDomain = window.location.hostname;

    setIsStaging(mainDomain.includes('self-service-6he.pages.dev'));
  }, []);

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        body: {
          '--layout-nav-zIndex': 1101,
          '--layout-nav-mobile-width': '320px',
          '--layout-header-blur': '8px',
          '--layout-header-zIndex': 1100,
          '--layout-footer-zIndex': 1100,
          '--layout-header-mobile-height': '64px',
          '--layout-header-desktop-height': '72px',
          ...cssVars,
        },
      }}
    />
  );

  const renderStagingAlert = (
    <Box sx={{ position: 'sticky', top: 0 }}>
      <Alert severity="error" sx={{ width: '100%', alignItems: 'center', borderRadius: 0 }}>
        <Typography variant="h5">You&apos;re on the Staging environment.</Typography>
      </Alert>
    </Box>
  );

  return (
    <>
      {inputGlobalStyles}

      <Box id="root__layout" className={layoutClasses.root} sx={sx}>
        {sidebarSection ? (
          <>
            {sidebarSection}
            <Box
              display="flex"
              flex="1 1 auto"
              flexDirection="column"
              className={layoutClasses.hasSidebar}
            >
              {isStaging && renderStagingAlert}
              {headerSection}
              {children}
              {footerSection}
            </Box>
          </>
        ) : (
          <>
            {isStaging && renderStagingAlert}
            {headerSection}
            {children}
            {footerSection}
          </>
        )}
      </Box>
    </>
  );
};
