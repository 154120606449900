import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_COURSE_RECOGNITIONS, FILTERED_COURSE_RECOGNITIONS } from '@api/course-recognition';

import type { CourseRecognition } from './types';

// ** Course-Recognition initial state
const courseRecognitionsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        course_id: 0,
        recognition_id: 0,
        recognition: {
          id: 0,
          name: null,
          logo: null,
        },
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface CourseRecognitionsState {
  edges: {
    cursor: string;
    node: Partial<CourseRecognition>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch CourseRecognitions
export const fetchCourseRecognitions = createAsyncThunk<CourseRecognition, any, {}>(
  'courseRecognitions/fetchCourseRecognitions',
  async (courseRecognitionsData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_RECOGNITIONS,
        variables: { ...courseRecognitionsData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered CourseRecognitions
export const fetchFilteredCourseRecognitions = createAsyncThunk<CourseRecognition, any, {}>(
  'courseRecognitions/fetchFilteredCourseRecognitions',
  async (courseRecognitionsData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_COURSE_RECOGNITIONS,
        variables: { ...courseRecognitionsData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const courseRecognitionsSlice = createSlice({
  name: 'courseRecognitions',
  initialState: {
    courseRecognitions: <CourseRecognitionsState>{
      ...courseRecognitionsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseRecognitions.fulfilled, (state, { payload }) => {
        const { course_recognitionCollection }: any = payload;

        state.courseRecognitions = course_recognitionCollection;
      })
      .addCase(fetchFilteredCourseRecognitions.fulfilled, (state, { payload }) => {
        const { course_recognitionCollection }: any = payload;

        state.courseRecognitions = course_recognitionCollection;
      });
  },
});

export default courseRecognitionsSlice.reducer;
