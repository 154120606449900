import type { RootState, AppDispatch } from '@redux/index';

import { Iconify } from '@components/iconify';
import { useBoolean } from '@hooks/use-boolean';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilteredBrands } from '@redux/features/brand';
import { setActiveBrand } from '@redux/features/brand/active';
import { useRef, useState, useEffect, useCallback } from 'react';
import { setAuthedUserBrands } from '@redux/features/brand/list';
import { BrandsPopover } from '@layouts/components/brands-popover';

import {
  Box,
  Chip,
  Card,
  Stack,
  Dialog,
  Button,
  Divider,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const PAGE_SIZE = 100;

// ----------------------------------------------------------------------

interface Props {
  open: boolean;
  isClient: boolean;
  accountId: number | string | null | undefined;
  handleSetOpenBrandSelector: (val: boolean) => void;
}

export const BrandSelector = ({ open, isClient, accountId, handleSetOpenBrandSelector }: Props) => {
  // ** States
  const [brandsData, setBrandsData] = useState<any[]>([]);
  const [brandsDataPageInfo, setBrandsDataPageInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchingBrands, setIsFetchingBrands] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [loadedFirstBatch, setLoadedFirstBatch] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [hasNoMatchingResults, setHasNoMatchingResults] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // ** Hooks
  const router = useRouter();
  const dialog = useBoolean();
  const dispatch = useDispatch<AppDispatch>();
  const observer = useRef<IntersectionObserver | null>(null);
  const { authSession } = useSelector((state: RootState) => state.authSession);

  // ** Vars
  const { capabilities } = authSession;

  const updateUrlPathname = useCallback(
    (newId: number | string) => {
      const updatedBrandUrl = `/dashboard/${newId}`;

      router.replace(updatedBrandUrl);
      router.refresh();
    },
    [router]
  );

  const handleChangeBrand = useCallback(
    async (newValue: (typeof brandsData)[0]) => {
      // Set brandId in localStorage
      window.localStorage.setItem('bId', `${newValue.id}`);

      // Update URL pathname
      updateUrlPathname(`${newValue.id}`);

      // Update global state
      await dispatch(setActiveBrand(newValue));
    },
    [dispatch, updateUrlPathname]
  );

  const getBrands = useCallback(
    async (pageNum: number) => {
      setIsLoading(true);
      setIsFetchingBrands(true);

      try {
        const hasNextPage = brandsDataPageInfo?.pageInfo?.hasNextPage || null;
        const loadNextPage = hasNextPage && pageNum > page;

        const results = await dispatch(
          fetchFilteredBrands({
            first: PAGE_SIZE,
            not_status: 'revision',
            ...(isClient && { account_id: accountId }),
            ...(loadNextPage && { after: brandsDataPageInfo?.pageInfo?.endCursor }),
          })
        );

        const {
          brandCollection: { edges, pageInfo },
        }: any = results.payload;

        const brandsArray = edges.map((item: any) => item.node);
        const brandIDs = [...brandsData, ...brandsArray].map((item: any) => item.id);

        setBrandsData((prev) => (pageNum === 0 ? brandsArray : [...prev, ...brandsArray]));
        setBrandsDataPageInfo({ pageInfo });

        if (pageNum === 0) setLoadedFirstBatch(true);

        // Set brandIds in localStorage
        window.localStorage.setItem('bIds', brandIDs.toString());

        // Set brands to global state
        await dispatch(
          setAuthedUserBrands((prev: any) =>
            pageNum === 0 ? brandsArray : [...prev, ...brandsArray]
          )
        );

        // Route to brand
        if (brandsArray.length === 1) handleChangeBrand(brandsArray[0]);
      } catch (error) {
        console.error('Error fetching brands:', error);
      } finally {
        setIsLoading(false);
        setIsFetchingBrands(false);
      }
    },
    [
      brandsDataPageInfo?.pageInfo?.hasNextPage,
      brandsDataPageInfo?.pageInfo?.endCursor,
      page,
      dispatch,
      isClient,
      accountId,
      brandsData,
      handleChangeBrand,
    ]
  );

  // Load more options when reaching the last item
  const lastOptionRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && brandsDataPageInfo?.pageInfo?.hasNextPage) {
          setPage((prevPage) => prevPage + 1);
          getBrands(page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [getBrands, isLoading, page, brandsDataPageInfo]
  );

  useEffect(() => {
    // Set open brand selector
    const bsValue = window.localStorage.getItem('bS');
    setOpenDialog(bsValue === 'false' ? false : open);

    if (!loadedFirstBatch) getBrands(0);
  }, [getBrands, loadedFirstBatch, open]);

  const handleSetValue = (val: string) => {
    setValue(val);
  };

  const handleFilter = useCallback(async () => {
    setIsLoading(true);

    const filteredBrands = await dispatch(
      fetchFilteredBrands({
        first: PAGE_SIZE,
        not_status: 'revision',
        ...(isClient && { account_id: accountId }),
        provider_name: `%${value}%`,
      })
    );

    setPage(0);

    const {
      brandCollection: { edges, pageInfo },
    }: any = filteredBrands.payload;

    const brandsArray = edges.map((item: any) => item.node);

    if (brandsArray.length > 0) {
      setBrandsData(brandsArray);
    } else {
      setHasNoMatchingResults(true);
    }
    setBrandsDataPageInfo({ pageInfo });

    setIsLoading(false);
  }, [accountId, dispatch, isClient, value]);

  const handleReset = () => {
    getBrands(0);
    setValue('');
  };

  const handleCloseSelector = () => {
    setOpenDialog(false);

    // Set in localStorage for persistence
    window.localStorage.setItem('bS', 'false');
  };

  return (
    <Dialog maxWidth="md" fullWidth open={openDialog} onClose={dialog.onFalse}>
      <DialogTitle>
        {isClient ? 'Select or Search a Brand' : 'Search or Select a Brand'}
      </DialogTitle>

      <DialogContent>
        <Typography sx={{ mb: 3 }}>
          {isClient
            ? 'Select a Brand below or search through the input.'
            : 'Search a Brand through the input or the dropdown below.'}
        </Typography>

        <Stack spacing={3} marginBlockEnd={5}>
          {isFetchingBrands ? (
            <Stack direction="row" alignItems="center" justifyContent="center" my={1}>
              <CircularProgress color="primary" />
            </Stack>
          ) : (
            <>
              {brandsData.length > 0 ? (
                <>
                  {isClient ? (
                    <Stack spacing={2} alignItems="center">
                      <Stack direction="row" spacing={1.5} alignItems="center">
                        {brandsData?.map((item, index) => (
                          <Box
                            key={index}
                            onClick={() => handleChangeBrand(item)}
                            sx={{
                              cursor: 'pointer',
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'none',
                                '& .MuiTypography-root': {
                                  textDecoration: 'none',
                                },
                                '& .MuiChip-label': {
                                  textDecoration: 'none',
                                },
                              },
                            }}
                          >
                            <Card
                              sx={{
                                p: 2,
                                '&:hover': {
                                  boxShadow: 8,
                                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                },
                              }}
                            >
                              <Typography variant="subtitle1" textAlign="center" fontWeight={400}>
                                {item.provider_name}
                              </Typography>
                            </Card>
                          </Box>
                        ))}
                      </Stack>

                      <Divider orientation="vertical">
                        <Chip label="or" size="small" sx={{ bgcolor: '#FFF', color: 'grey' }} />
                      </Divider>

                      <BrandsPopover
                        data-slot="workspaces"
                        inputType="search"
                        data={brandsData}
                        lastOptionRef={lastOptionRef}
                        value={value}
                        handleSetValue={handleSetValue}
                        handleFilter={handleFilter}
                        handleReset={handleReset}
                        hasNoMatchingResults={hasNoMatchingResults}
                      />
                    </Stack>
                  ) : (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <BrandsPopover
                        data-slot="workspaces"
                        inputType="search"
                        data={brandsData}
                        lastOptionRef={lastOptionRef}
                        value={value}
                        handleSetValue={handleSetValue}
                        handleFilter={handleFilter}
                        handleReset={handleReset}
                        hasNoMatchingResults={hasNoMatchingResults}
                      />

                      <Divider orientation="vertical">
                        <Chip label="or" size="small" sx={{ bgcolor: '#FFF', color: 'grey' }} />
                      </Divider>

                      <BrandsPopover
                        data-slot="workspaces"
                        inputType="list"
                        data={brandsData}
                        lastOptionRef={lastOptionRef}
                      />
                    </Stack>
                  )}
                </>
              ) : (
                <>
                  {capabilities.includes('brand.create') && (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      <Button
                        component={RouterLink}
                        href={paths.dashboard.client.brand.create}
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                        onClick={() => handleSetOpenBrandSelector(false)}
                      >
                        Create New Brand
                      </Button>
                    </Stack>
                  )}

                  {!isClient && (
                    <>
                      <Divider textAlign="center">OR</Divider>

                      <Stack direction="row" justifyContent="center" alignItems="center">
                        <Button variant="outlined" color="primary" onClick={handleCloseSelector}>
                          Proceed to Dashboard
                        </Button>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
