import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';

import { varAlpha } from 'src/theme/styles';

import { Iconify } from '../iconify';
import { DeleteButton, SingleFilePreview } from './components/preview-single-file';

import type { UploadProps } from './types';

// ----------------------------------------------------------------------

export const UploadBox = ({
  value,
  placeholder,
  error,
  disabled,
  sx,
  onDelete,
  ...other
}: UploadProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    ...other,
  });

  const hasError = isDragReject || error;

  const hasFile = !!value;

  return (
    <Box sx={{ width: 1, position: 'relative' }}>
      <Box
        {...getRootProps()}
        sx={{
          width: 64,
          height: 64,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1,
          cursor: 'pointer',
          alignItems: 'center',
          color: 'text.disabled',
          justifyContent: 'center',
          bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.08),
          border: (theme) => `dashed 1px ${varAlpha(theme.vars.palette.grey['500Channel'], 0.16)}`,
          ...(isDragActive && { opacity: 0.72 }),
          ...(disabled && { opacity: 0.48, pointerEvents: 'none' }),
          ...(hasError && {
            color: 'error.main',
            borderColor: 'error.main',
            bgcolor: (theme) => varAlpha(theme.vars.palette.error.mainChannel, 0.08),
          }),
          '&:hover': { opacity: 0.72 },
          ...(hasFile ? { padding: '28% 0' } : { py: 2.5 }),
          ...sx,
        }}
      >
        <input {...getInputProps()} />

        {/* Single file */}
        {hasFile ? (
          <SingleFilePreview file={value as File} />
        ) : (
          <>{placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />}</>
        )}
      </Box>

      {hasFile && <DeleteButton onClick={onDelete} />}
    </Box>
  );
};
