import apolloClient from '@lib/apollo';
import { GET_FAQS, FILTERED_FAQS } from '@api/faq';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { FAQ } from './types';

// ** FAQ initial state
const faqsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: '',
        title: '',
        content: '',
        images: null,
        category: '',
        author: null,
        faqs: null,
        keywords: null,
        video: null,
        quick_links: null,
        status: null,
        impressions: null,
        created_at: null,
        profiles: {
          first_name: null,
          last_name: null,
          avatar_url: null,
        },
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface FAQsState {
  edges: {
    cursor: string;
    node: Partial<FAQ>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch FAQs
export const fetchFAQs = createAsyncThunk<FAQ, any, {}>(
  'faqs/fetchFAQs',
  async (faqData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_FAQS,
        variables: { ...faqData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered FAQs
export const fetchFilteredFAQs = createAsyncThunk<FAQ, any, {}>(
  'faqs/fetchFilteredFAQs',
  async (faqData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_FAQS,
        variables: { ...faqData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const faqsSlice = createSlice({
  name: 'faqs',
  initialState: {
    faqs: <FAQsState>{
      ...faqsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFAQs.fulfilled, (state, { payload }) => {
        const { faqCollection }: any = payload;

        state.faqs = faqCollection;
      })
      .addCase(fetchFilteredFAQs.fulfilled, (state, { payload }) => {
        const { faqCollection }: any = payload;

        state.faqs = faqCollection;
      });
  },
});

export default faqsSlice.reducer;
