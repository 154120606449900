export const updateUrlBrandId = (pathname: string, newId: number, update: boolean = false) => {
  // Get the current URL and pathname
  const pathParts = pathname.split('/');

  // Find the first number in the URL (which represents the brand ID)
  const idIndex = pathParts.findIndex((part) => !Number.isNaN(Number(part)) && part !== '');

  if (idIndex !== -1) {
    // If a brand ID already exists, replace it with the new one
    // @ts-ignore
    if (update) pathParts[idIndex] = newId;
  } else {
    // If no brand ID exists, insert the new one after '/dashboard'
    const dashboardIndex = pathParts.indexOf('dashboard');
    if (dashboardIndex !== -1) {
      pathParts.splice(dashboardIndex + 1, 0, `${newId}`);
    }
  }

  // Construct the new URL path
  const newPath = pathParts.join('/');

  return newPath;
};

export const getBrandId = (url: string) => {
  const match = url.match(/dashboard\/(\d+)/);

  return match ? match[1] : null;
};
